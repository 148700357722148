import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import LogoutPage from "./Logout";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IMAGES, SVGICON } from "../../constant/theme";
import Logoutbtn from "./Logoutbtn";
import { Offcanvas } from "react-bootstrap";
const Header = ({ updateAdminData, onNote }) => {

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);
  const [minimumAge, setMinimumAge] = useState('');
  const [maximumAge, setMaximumAge] = useState('');
  const [headerFix, setheaderFix] = useState(false);
  const [instagramLink, setInstagramLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [facebookLink, setFacebookLink] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [appIntroduction, setappIntroduction] = useState('');
  const [email, setEmail] = useState('');
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [adminId, setAdminId] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const editAgeAPI = async (fromAge, toAge) => {
    const token = localStorage.getItem('token');

    if (!token) {
      // Handle case where token is missing
      throw new Error('Token not found in localStorage');
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_USER_PROFILE_API_URI}/editsettings`, {
        fromAge: fromAge,
        toAge: toAge,
        instagramLink: instagramLink,
        twitterLink: twitterLink,
        facebookLink: facebookLink,
        youtubeLink: youtubeLink,
        phoneNumber: phoneNumber,
        appIntroduction: appIntroduction,
        email: email,
        id: 1,
      }, {
        headers: {
          'authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      console.log('API Response:', response.data);
      setShowOffcanvas(false);
      toast.success('Settings Updated');

    } catch (error) {
      console.error('Error editing age:', error);
      toast.error('An error occurred while updating age settings. Please try again.');
    }
  };


  const handleUpdate = async () => {
    console.log("max", maximumAge);
    const maxAgeValue = !isNaN(parseFloat(maximumAge)) ? parseFloat(maximumAge) : maximumAge;
    console.log(maxAgeValue);
    try {
      await editAgeAPI(minimumAge, maximumAge);
    } catch (error) {
      console.error('Error updating age settings:', error);
      setErrorMessage('An error occurred while updating age settings. Please try again.');
    }
  };


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  const fetchAdminData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_USER_PROFILE_API_URI}/getadmin`, {
        headers: {
          'authorization': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      const { firstName, galleries } = response.data.data;

      setAdminData({ firstName, galleries });

      console.log(adminData)
    } catch (error) {
      console.error('Error fetching admin data:', error);
    }
  };

  useEffect(() => {
    fetchAdminData();
    getAgeAPI();
  }, []);


  const getAgeAPI = async () => {
    const token = localStorage.getItem('token');
    console.log(token);
    if (!token) {
      throw new Error('Token not found in localStorage');
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_USER_PROFILE_API_URI}/getsettings/1`, {
        headers: {
          'authorization': token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      const { fromAge, toAge, instagramLink, twitterLink, youtubeLink, facebookLink, phoneNumber, email ,appIntroduction } = response.data.data;

      setMinimumAge(fromAge);
      setMaximumAge(toAge);
      setInstagramLink(instagramLink);
      setTwitterLink(twitterLink);
      setYoutubeLink(youtubeLink);
      setFacebookLink(facebookLink);
      setPhoneNumber(phoneNumber);
      setappIntroduction(appIntroduction);
      setEmail(email);
    } catch (error) {
      console.error('Error fetching age settings:', error);

    }
  };
  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
    if (!showOffcanvas) {

      getAgeAPI();
    }
  };
  useEffect(() => {
    fetchAdminData();
    if (updateAdminData) {
      updateAdminData(fetchAdminData);
    }
  }, [updateAdminData]);
  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div className="input-group search-area">{/*  */}</div>
            </div>
            <ul className="navbar-nav header-right">
              <>
                <div
                  style={{ padding: "10px" }}
                  className=" header-right notification_dropdown nav-link"
                  onClick={toggleOffcanvas}
                  title="Settings"

                >
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.8067 7.62358L20.1842 6.54349C19.6577 5.62957 18.4907 5.31429 17.5755 5.83869V5.83869C17.1399 6.09531 16.6201 6.16812 16.1307 6.04106C15.6413 5.91399 15.2226 5.59749 14.9668 5.16134C14.8023 4.88412 14.7139 4.56836 14.7105 4.24601V4.24601C14.7254 3.72919 14.5304 3.22837 14.17 2.85764C13.8096 2.48691 13.3145 2.27783 12.7975 2.27805H11.5435C11.037 2.27804 10.5513 2.47988 10.194 2.83891C9.83669 3.19795 9.63717 3.68456 9.63961 4.19109V4.19109C9.6246 5.23689 8.77248 6.07678 7.72657 6.07667C7.40421 6.07332 7.08846 5.98491 6.81123 5.82038V5.82038C5.89606 5.29598 4.72911 5.61126 4.20254 6.52519L3.53435 7.62358C3.00841 8.53636 3.3194 9.70258 4.23 10.2323V10.2323C4.8219 10.574 5.18653 11.2056 5.18653 11.889C5.18653 12.5725 4.8219 13.204 4.23 13.5458V13.5458C3.32056 14.0719 3.00923 15.2353 3.53435 16.1453V16.1453L4.16593 17.2346C4.41265 17.6798 4.8266 18.0083 5.31619 18.1474C5.80578 18.2866 6.33064 18.2249 6.77462 17.976V17.976C7.21108 17.7213 7.73119 17.6515 8.21934 17.7822C8.70749 17.9128 9.12324 18.233 9.37416 18.6716C9.5387 18.9489 9.62711 19.2646 9.63046 19.587V19.587C9.63046 20.6435 10.487 21.5 11.5435 21.5H12.7975C13.8505 21.5 14.7055 20.6491 14.7105 19.5961V19.5961C14.7081 19.088 14.9089 18.6 15.2682 18.2407C15.6275 17.8814 16.1155 17.6806 16.6236 17.6831C16.9452 17.6917 17.2596 17.7797 17.5389 17.9394V17.9394C18.4517 18.4653 19.6179 18.1543 20.1476 17.2437V17.2437L20.8067 16.1453C21.0618 15.7075 21.1318 15.186 21.0012 14.6963C20.8706 14.2067 20.5502 13.7893 20.111 13.5366V13.5366C19.6718 13.2839 19.3514 12.8665 19.2208 12.3769C19.0902 11.8873 19.1603 11.3658 19.4154 10.9279C19.5812 10.6383 19.8214 10.3982 20.111 10.2323V10.2323C21.0161 9.70286 21.3264 8.54346 20.8067 7.63274V7.63274V7.62358Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="12.1751"
                      cy="11.889"
                      r="2.63616"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <Offcanvas
                  show={showOffcanvas}
                  onHide={toggleOffcanvas}
                  placement="end"
                  style={{ width: "900px" }}
                >
                  <Offcanvas.Header closeButton className="text-white profile-head-h">

                    <Offcanvas.Title>
                      <h3 className="modal-title text-white" id="#gridSystemModal">Settings</h3>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <h4>Athlete Age </h4>
                    <div className="row p-2 pl-0 mb-3">
                      <div className="col-lg-12 d-flex mb-3 justify-content-between">
                        <div className="col-lg-5">
                          <label htmlFor="minimumAge" className="form-label">
                            Minimum Age
                          </label>
                          <input
                            type="number"
                            className="form-control w-80"
                            id="minimumAge"
                            value={minimumAge}
                            onChange={(e) => setMinimumAge(e.target.value)}
                            placeholder="Minimum Age"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="maximumAge" className="form-label">
                            Maximum Age
                          </label>
                          <input
                            type="text"
                            className="form-control w-80"
                            id="maximumAge"
                            value={maximumAge}
                            onChange={(e) => setMaximumAge(e.target.value)}
                            placeholder="Maximum Age"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          className="form-control w-80"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="email"
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label htmlFor="phoneNumber" className="form-label">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control w-80"
                          id="phoneNumber"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder="Phone Number"
                        />
                      </div>
                     

                      <div className="col-lg-12 mb-3">
                        <label htmlFor="instagramLink" className="form-label">
                          Instagram Link
                        </label>
                        <input
                          type="text"
                          className="form-control w-80"
                          id="instagramLink"
                          value={instagramLink}
                          onChange={(e) => setInstagramLink(e.target.value)}
                          placeholder="Instagram Profile Link"
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label htmlFor="twitterLink" className="form-label">
                          Twitter Link
                        </label>
                        <input
                          type="text"
                          className="form-control w-80"
                          id="twitterLink"
                          value={twitterLink}
                          onChange={(e) => setTwitterLink(e.target.value)}
                          placeholder="Twitter Link"
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label htmlFor="youtubeLink" className="form-label">
                          Youtube Link
                        </label>
                        <input
                          type="text"
                          className="form-control w-80"
                          id="youtubeLink"
                          value={youtubeLink}
                          onChange={(e) => setYoutubeLink(e.target.value)}
                          placeholder="Youtube Link"
                        />
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label htmlFor="facebookLink" className="form-label">
                          Facebook Link
                        </label>
                        <input
                          type="text"
                          className="form-control w-80"
                          id="facebookLink"
                          value={facebookLink}
                          onChange={(e) => setFacebookLink(e.target.value)}
                          placeholder="Facebook Link"
                        />
                      </div>

                    </div>
                    <div className="col-lg-12 mb-3">
                        <label htmlFor="appIntroduction" className="form-label">
                          App Introduction
                        </label>
                        <textarea
                          className="form-control w-80"
                          id="appIntroduction"
                          value={appIntroduction}
                          onChange={(e) => setappIntroduction(e.target.value)}
                          placeholder="App Introduction"
                          rows="8" // Adjust the number of rows as needed
                        ></textarea>
                      </div>
                    <div className=" mb-3 p-3 pl-0">
                      <button
                        type="submit"
                        className="btn btn-primary me-1"
                        onClick={handleUpdate}
                      >
                        Update
                      </button>
                      {updateSuccess && (
                        <div className="alert alert-success" role="alert">
                          settings updated successfully!
                        </div>
                      )}

                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </>
              {/* <Dropdown
                as="li"
                className="nav-item dropdown notification_dropdown "
              >
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  onClick={() => onNote()}
                  title="Chat"

                >
                  <svg

                    style={{
                      width: '23px',
                      height: ' 23px'
                    }}

                    width="20"
                    height="22"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.9026 6.85114L12.4593 10.4642C11.6198 11.1302 10.4387 11.1302 9.59922 10.4642L5.11844 6.85114"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.9089 19C18.9502 19.0084 21 16.5095 21 13.4384V6.57001C21 3.49883 18.9502 1 15.9089 1H6.09114C3.04979 1 1 3.49883 1 6.57001V13.4384C1 16.5095 3.04979 19.0084 6.09114 19H15.9089Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Dropdown.Toggle>
              </Dropdown> */}

              <li className="nav-item ps-3">
                {adminData ? (
                  <Dropdown className="header-profile2" title="Account">
                    <Dropdown.Toggle className="nav-link i-false" as="div">
                      <div className="header-info2 d-flex align-items-center">
                        <div className="header-media">
                          <img src={adminData.galleries?.[0]?.fileLocation} alt="" />
                        </div>
                        <div className="header-info" style={{ marginTop: '10px !important' }}>
                          <h6 className="mb--7">{adminData.firstName}</h6>
                        </div>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <div className="card border-0 mb-0">
                        <div className="card-header py-2">
                          <div className="products">
                            <img src={adminData.galleries?.[0]?.fileLocation} alt="" width={25} height={35} />
                            <div>
                              <h6>{adminData.firstName}</h6>
                            </div>
                          </div>
                        </div>
                        <div className="card-body px-0 py-2">
                          <Link to={"/admin-profile/"} className="dropdown-item ai-icon">
                            {SVGICON.User} <span className="ms-2">Profile</span>
                          </Link>
                          <Link to={"/change-password/"} className="dropdown-item ai-icon">
                            {SVGICON.Headersetting} <span className="ms-2">Change Password</span>
                          </Link>
                        </div>
                        <div className="card-footer px-0 py-2">
                          <LogoutPage />
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <span>Loading...</span>
                )}
              </li>

            </ul>
          </div>
        </nav>
      </div >
    </div >
  );
};

export default Header;
