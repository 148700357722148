import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import MainPagetitle from "../../layouts/MainPagetitle";
import CoachOffcanvas from "../../constant/CoachOffcanvas";
import swal from "sweetalert";
import Collapse from "react-bootstrap/Collapse";
import ViewCoachOffcanvas from "../../constant/ViewCoachOffcanvas";
import { toast } from "react-toastify";
import EditCoachOffcanvas from "../../constant/EditCoach";
import axios from "axios";
import { Spinner, Form } from "react-bootstrap";
import noimage from '../../../images/noprofile/noprofiel.png'
import { CSVLink } from 'react-csv';
import parse from 'html-react-parser';
import Select from 'react-select';
import Message from "../../constant/MessageListOffcanvas";

const stripHtmlTags = (html) => {
  const parsedHtml = parse(html);
  const styledHtml = React.Children.map(parsedHtml, (child) => {
    if (React.isValidElement(child)) {
      const style = child.props.style;
      if (style) {
        const styleString = Object.entries(style)
          .map(([key, value]) => `${key}:${value}`)
          .join(';');
        return React.cloneElement(child, { style: styleString });
      }
    }
    return child;
  });
  return styledHtml;
};

const CoachProfile = () => {

  const [open] = useState(true);
  const [loading, setLoading] = useState(true);
  const [statusPriority, setStatusPriority] = useState(null);
  const [data, setData] = useState([]);
  const [sportName, setSportName] = useState('');
  const [city, setCity] = useState(''); // State for city input value
  const [selectedStateIds, setSelectedStateIds] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedSports, setSelectedSports] = useState([]);
  const [publishStatus, setPublishStatus] = useState("");

  const [sports, setSports] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const [activePag, setActivePag] = useState(() => {
    const storedPage = sessionStorage.getItem('activePag');
    return storedPage ? parseInt(storedPage) : 0;
  });
  const sort = 10;

  // Pagination

  const pagination = useMemo(() => Array.from({ length: Math.ceil(data.length / sort) }, (_, index) => index + 1), [data]);

  // Fetch sports data
  useEffect(() => {

    fetchSports();
  }, []);
  const fetchSports = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_SPORTS_API_URI}

/getallsports`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      setSports([{ id: '0', sportName: 'All' }, ...response.data.data]);
    } catch (error) {
      console.error('Error fetching sports:', error);
    }
  };

  // Fetch states data
  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_STATE_TAX_API_URI}/getallstates`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      setStates(response.data.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  // Fetch cities based on selected state
  useEffect(() => {
    if (selectedStateIds.length > 0) {
      fetchCitiesByState(selectedStateIds.map(state => state.value).join(","));
    } else {
      setCities([]);
    }
  }, [selectedStateIds]);

  const fetchCitiesByState = async (stateIds) => {
    try {
      const token = localStorage.getItem('token');
      setCities([]);
      const response = await axios.get(`${process.env.REACT_APP_STATE_TAX_API_URI}/getallcities`, {
        params: { stateId: stateIds },
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      setCities(response.data.citiesData);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  // Fetch coach data
  useEffect(() => {
    fetchCoach();
  }, []);

  const fetchCoach = async (page = 1) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getallcoach?page=${page}`,
        {
          headers: {
            Authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setStatusPriority(response?.data?.data);
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching coach data:", error);
      if (error.response && error.response.status === 400) {
        toast.warn(error?.response.data);
      } else {
        toast.error(error?.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (page = 1) => {
    try {
      const token = localStorage.getItem("token");
      const stateIds = selectedStateIds.map(state => state.value).join(",");
      const cityIds = selectedCities.map(city => city.value).join(",");
      const sportIds = selectedSports.map(sport => sport.value).join(",");

      const response = await axios.get(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/getallcoach`,
        {
          params: {
            page: page,
            limit: 20,
            city: cityIds,
            state: stateIds,
            sportsId: sportIds,
            isPublish: publishStatus?.value || "",
          },
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setStatusPriority(response?.data?.data);
        setData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching coach data:", error);
      if (error.response && error.response.status === 400) {
        toast.warn(error?.response.data);
      } else {
        toast.error(error?.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setSelectedStateIds([]);
    setSelectedCities([]);
    setSelectedSports([]);
    setPublishStatus("");
    setStates([]);
    setCities([]);
    fetchStates();
    fetchSports();
  };

  useEffect(() => {
    handleSearch();
  }, [selectedStateIds, selectedCities, selectedSports, publishStatus]);

  const options = sports.map(sport => ({
    value: sport.id,
    label: sport.sportName
  }));

  const csvData = data.map(coach => ({
    id: coach.id,
    firstName: coach.user.firstName,
    lastName: coach.user.lastName,
    email: coach.user.email,
    phone: coach.phone,
    city: coach?.citiesData?.map(city => city.name).join(', '),
    state: coach?.stateData?.map(state => state.name).join(', '),
    twitterLink: coach.twitterLink,
    instagramLink: coach.instagramLink,
    websiteLink: coach.websiteLink,
    bio: stripHtmlTags(coach.bio),
    sport: coach?.sport?.sportName,
    coachSpecialty: coach.coachSpecialty,
    ageYouCoach: coach.ageYouCoach,
    genderYouCoach: coach.genderYouCoach,
    achievements: stripHtmlTags(coach.achievements),
    isApprove: coach.isApprove,
    isActive: coach.isActive ? 'Active' : 'Inactive'
  }));
  const onClick = (page) => {
    setActivePag(page);
    sessionStorage.setItem('activePag', page);
    fetchCoach(page + 1);
  };

  const handleDelete = async (id) => {
    const willDelete = await swal({
      title: "Are you sure you want to Archive?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (willDelete) {
      try {
        let token = localStorage.getItem("token");
        if (!token) {
          swal("Authentication error!", { icon: "error" });
          return;
        }

        const response = await axios.post(
          `${process.env.REACT_APP_USER_PROFILE_API_URI}/deletecoach/${id}`,
          {},
          {
            headers: {
              authorization: token,
            },
          }
        );
        if (response?.status === 200) {
          swal("Data deleted successfully!", { icon: "success" });
          fetchCoach();
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          swal("Data already deleted!", { icon: "warning" });
        } else {
          swal("Internal server error!", { icon: "error" });
        }
        console.error("Error deleting user:", error);
      }
    } else {
      swal("Your data is safe!", { icon: "info" });
    }
  };

  const handleCoachUpdated = () => {
    fetchCoach();
  };


  const handlePublishStatus = async (id, isPublish) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/coachispublish/${id}`,
        { isPublish: isPublish },
        {
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setStatusPriority(data.map(item => {
          if (item.id === id) {
            return { ...item, isPublish: isPublish };
          }
          return item;
        }));
        setData(data.map(item => {
          if (item.id === id) {
            return { ...item, isPublish: isPublish };
          }
          return item;
        }));
        toast.success("Status Updated!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  }




  useEffect(() => {
    if (sportName === '' && city === '' && selectedStateIds === '' && publishStatus === '') {
      handleSearch();
      fetchStates();
      fetchSports();
    }
  }, [sportName, city, selectedStateIds, publishStatus]);
  const handleStatusChange = async (id, isApprove) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_USER_PROFILE_API_URI}/coachisapprove/${id}`,
        { isApprove: isApprove },
        {
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response.data.message);
      console.log(error);
    }
  }


  const viewcoach = useRef();
  const employee = useRef();
  const employe = useRef();
  const message = useRef();
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Spinner className="text-center" animation="border" variant="primary" />
      </div>
    );
  }
  const splitIntoLines = (text, wordsPerLine) => {
    const words = text.split(' ');
    let result = '';
    for (let i = 0; i < words.length; i += wordsPerLine) {
      result += words.slice(i, i + wordsPerLine).join(' ') + '<br>';
    }
    return result;
  };
  return (
    <>
      <MainPagetitle
        mainTitle="Coach "
        pageTitle={"Coach"}
        parentTitle={"Home"}
      />

      <div className=" ">
        <div className="">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                    <div className="tbl-caption d-flex justify-content-end text-wrap align-items-center">
                      {/* <h4 className="heading mb-0">Coach</h4> */}
                      <div>
                        <Link
                          to={"#"}
                          className="btn btn-primary btn-sm ms-1"
                          data-bs-toggle="offcanvas"
                          onClick={() => employe.current.showEmployeModal()}
                        >
                          + Add Coach
                        </Link>
                      </div>
                      <div className="tbl-caption d-flex justify-content-end text-wrap align-items-center">
                        <CSVLink data={csvData} filename={"coach_data.csv"}>
                          <button className="btn btn-primary btn-sm ms-1">Export</button>
                        </CSVLink>
                      </div>

                    </div>
                    <Collapse in={open}>
                      <div className="cm-content-body form excerpt">
                        <div className="card-body">
                          <div className="row filter-row">

                            <div className="col-xl-2 col-sm-6 py-1">
                              <Select
                                id="state"
                                name="state"
                                className="mb-xl-0 mb-3"
                                placeholder="Select State"
                                options={states.map(state => ({ value: state.id, label: state.name }))}
                                value={selectedStateIds}
                                onChange={(selectedOptions) => setSelectedStateIds(selectedOptions)}
                                isMulti
                              />
                            </div>


                            <div className="col-xl-2 col-sm-6 py-1">
                              <Select
                                id="city"
                                name="city"
                                className="mb-xl-0 mb-3"
                                placeholder="Select City"
                                options={cities.map(city => ({ value: city.id, label: city.name }))}
                                value={selectedCities}
                                onChange={(selectedOptions) => setSelectedCities(selectedOptions)}
                                isMulti
                                isDisabled={!selectedStateIds.length}
                              />
                            </div>

                            <div className="col-xl-3 col-sm-3 py-1">
                              <Select
                                className="mb-xl-0 mb-3"
                                placeholder="Select Publish Status"
                                options={[
                                  { value: "1", label: "Active" },
                                  { value: "0", label: "Inactive" },
                                ]}
                                value={publishStatus}
                                onChange={setPublishStatus}
                                isClearable
                              />
                            </div>
                            {/* <div className="col-xl-1 col-sm-1 py-1 text-center">
                              <button
                                className="btn btn-primary me-2"
                                title="Click here to Search"
                                type="button"
                                onClick={() => handleSearch(1)}
                              >
                                <i className="fa fa-search me-1"></i>
                              </button>
                            </div> */}
                            <div className="col-xl-1 col-sm-1 py-1">
                              <button
                                className="btn btn-primary me-2"
                                title="Click here to Reset"
                                type="button"
                                onClick={handleReset}
                              >
                                <i className="bi bi-arrow-clockwise me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                    <div
                      id="employee-tbl_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="empoloyees-tblwrapper"
                        className="table ItemsCheckboxSec dataTable no-footer mb-0"
                      >
                        <thead>
                          <tr>
                            <th>Profile</th>

                            <th> First name </th>
                            <th> Last Name </th>
                            <th> Sport </th>
                            <th> Age You Coach </th>
                            <th> Gender You Coach </th>
                            <th>City you Coach</th>
                            <th>State you Coach</th>
                            <th>Email Address</th>
                            <th>Phone Number</th>
                            <th>Approval Status</th>
                            <th>Publish Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statusPriority?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {item?.user?.galleries && item.user.galleries.length > 0 ? (
                                  <img
                                    src={item.user.galleries[0].fileLocation}
                                    className="avatar avatar-md"
                                    alt={item.user.galleries[0].description || 'Gallery Image'}
                                  />
                                ) : (
                                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                  <img
                                    src={noimage}
                                    className="avatar avatar-md"
                                    alt="no image"
                                  />
                                )}
                              </td>

                              <td>
                                <span>{item?.user?.firstName}</span>
                              </td>
                              <td>
                                <span>{item?.user?.lastName}</span>

                              </td>
                              <td>
                                {item?.sports?.map((sport, i) => (
                                  <span key={i}>
                                    {sport.sportName}{i < item.sports.length - 1 ? ', ' : ''}
                                  </span>
                                ))}
                              </td>
                              <td>
                                <span>{item?.ageYouCoach}</span>
                              </td>
                              <td>
                                <span>{item?.genderYouCoach}</span>
                              </td>
                              <td>
                                <div
                                  style={{ width: '100%' }}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.citiesData
                                      ?.map((cityItem, cityIndex) =>
                                        cityIndex > 0 ? `, ${cityItem?.name}` : cityItem?.name
                                      )
                                      .join(' ')
                                      .split('.')
                                      .map(sentence => splitIntoLines(sentence, 10))
                                      .join('')
                                  }}
                                />
                              </td>
                              <td>
                                <div
                                  style={{ width: '100%' }}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.stateData
                                      ?.map((stateItem, stateIndex) =>
                                        stateIndex > 0 ? `, ${stateItem?.name}` : stateItem?.name
                                      )
                                      .join(' ')
                                      .split('.')
                                      .map(sentence => splitIntoLines(sentence, 10))
                                      .join('')
                                  }}
                                />
                              </td>


                              <td>
                                <span className="text-primary">
                                  {item?.user?.email}
                                </span>
                              </td>
                              <td>
                                <span>{item?.phone}</span>
                              </td>

                              <td className="custom-select-kr">
                                <select
                                  defaultValue={item?.isApprove}
                                  className="form-control"
                                  onChange={(event) =>
                                    handleStatusChange(
                                      item?.user?.id,
                                      event.target.value
                                    )
                                  }
                                >
                                  <option value="Approve">Approve</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Reject">Reject</option>
                                </select>
                              </td>
                              <td className="text-center">
                                <Form.Check
                                  type="switch"
                                  id={`custom-switch-${item?.user?.id}`}
                                  checked={item?.isPublish}
                                  onChange={(event) =>
                                    handlePublishStatus(item?.id, event.target.checked)
                                  }
                                />
                              </td>



                              <td>
                                <div className="d-flex">
                                  <div className="p-1">
                                    <Link
                                      to={"#"}
                                      className="btn  btn-sharp"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        viewcoach.current.showCoachModal(item.user.id);
                                      }}
                                    >
                                      <svg
                                        width="15"
                                        height="16"
                                        viewBox="0 0 15 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M0.625 8C0.625 8 3.125 3 7.5 3C11.875 3 14.375 8 14.375 8C14.375 8 11.875 13 7.5 13C3.125 13 0.625 8 0.625 8Z"
                                          stroke="var(--primary)"
                                          strokeWidth="1.2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                        <path
                                          d="M7.5 9.875C8.53553 9.875 9.375 9.03553 9.375 8C9.375 6.96447 8.53553 6.125 7.5 6.125C6.46447 6.125 5.625 6.96447 5.625 8C5.625 9.03553 6.46447 9.875 7.5 9.875Z"
                                          stroke="var(--primary)"
                                          strokeWidth="1.2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                    </Link>{" "}
                                  </div>
                                  <div className="p-1">
                                    <Link
                                      to={"#"}
                                      className="btn  light btn-sharp"
                                      data-bs-toggle="offcanvas"
                                      onClick={() =>
                                        employee.current.showEmployeModal(item.user.id)
                                      }
                                    >
                                      <svg
                                        width="15"
                                        height="16"
                                        viewBox="0 0 15 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.625 2.37519C10.7892 2.21104 10.984 2.08082 11.1985 1.99199C11.413 1.90315 11.6429 1.85742 11.875 1.85742C12.1071 1.85742 12.337 1.90315 12.5515 1.99199C12.766 2.08082 12.9608 2.21104 13.125 2.37519C13.2892 2.53934 13.4194 2.73422 13.5082 2.94869C13.597 3.16317 13.6428 3.39304 13.6428 3.62519C13.6428 3.85734 13.597 4.08721 13.5082 4.30168C13.4194 4.51616 13.2892 4.71104 13.125 4.87519L4.6875 13.3127L1.25 14.2502L2.1875 10.8127L10.625 2.37519Z"
                                          stroke="var(--primary)"
                                          strokeWidth="1.2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </Link>
                                  </div>
                                  <div className="p-1">
                                    <Link
                                      to={"#"}
                                      className="btn light btn-sharp"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        message.current.showEmployeModal(item?.user?.id);
                                      }}
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-text" viewBox="0 0 16 16">
                                        <path d="M2.678 11.894a1 1 0 0 1 .287.801 11 11 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8 8 0 0 0 8 14c3.996 0 7-2.807 7-6s-3.004-6-7-6-7 2.808-7 6c0 1.468.617 2.83 1.678 3.894m-.493 3.905a22 22 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a10 10 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105" />
                                        <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8m0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5" />
                                      </svg>
                                    </Link>{" "}
                                  </div>
                                  <div className="p-1 d-flex align-items-center">
                                    {/* <Link to={"#"} className="btn  btn-sharp"> */}
                                    <button
                                      onClick={() => handleDelete(item?.user?.id)}
                                      className="bg-white"
                                    >
                                      <i class="bi bi-archive text-danger "></i>
                                    </button>
                                    {/* </Link> */}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing  {activePag + 1} page of {data.length} entries
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers" id="example2_paginate">
                          <Link
                            className={`paginate_button previous ${activePag === 0 ? 'disabled' : ''}`}
                            to=""
                            onClick={(e) => {
                              if (activePag > 0) {
                                onClick(activePag - 1);
                              } else {
                                e.preventDefault();
                              }
                            }}
                          >
                            <i className="fa-solid fa-angle-left" />
                          </Link>
                          <span>
                            {pagination.map((number, i) => (
                              <Link
                                key={i}
                                to=""
                                className={`paginate_button ${activePag === i ? 'current' : ''}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  onClick(i);
                                }}
                              >
                                {number}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className={`paginate_button next ${activePag + 1 >= pagination.length ? 'disabled' : ''}`}
                            to=""
                            onClick={(e) => {
                              if (activePag + 1 < pagination.length) {
                                onClick(activePag + 1);
                              } else {
                                e.preventDefault();
                              }
                            }}
                          >
                            <i className="fa-solid fa-angle-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Message ref={message} Title="Message" />
      <CoachOffcanvas ref={employe} Title="Add Coach" onCoachUpdated={handleCoachUpdated} />
      <EditCoachOffcanvas ref={employee} Title="Update Coach" onCoachUpdated={handleCoachUpdated} />
      <ViewCoachOffcanvas ref={viewcoach} Title="View Coach Details" />
    </>
  );
};

export default CoachProfile;
