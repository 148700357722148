import React, { Fragment, useState } from "react";
import axios from 'axios';
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
//import RightSideBar from "./RightSideBar";
import ChatBox from "../ChatBox";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const fetchAdminData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('Token not found in localStorage');
            return;
        }

        const config = {
            headers: {
                authorization: ` ${token}`
            }
        };

        const response = await axios.get(`${process.env.REACT_APP_USER_PROFILE_API_URI}/getadmin`, config);
        console.log('Response:', response.data);
       
    } catch (error) {
        console.error('Error fetching user data:', error);

     
    }
};

  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  return (
    <Fragment>
	  
      <NavHader />
        <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
        <Header
            onNote={() => onClick("chatbox")}
            onNotification={() => onClick("notification")}
            onProfile={() => onClick("profile")}
            toggle={toggle}
            title={title}
            onBox={() => onClick("box")}
            onClick={() => ClickToAddEvent()}
            updateAdminData={fetchAdminData}
          /> 
        <SideBar />
    </Fragment>
  );
};

export default JobieNav;
