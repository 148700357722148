import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import MainPagetitle from "../../layouts/MainPagetitle";
import SubscriptionOffcanvas from "../../constant/SubscriptionOffcanvas";
import EditSubscriptionoffcanvas from "../../constant/EditSubscriptionOffcanvas";
import ViewSubscriptionOffcanvas from "../../constant/ViewSubscriptionOffcanvas";
import { toast } from "react-toastify";
import axios from 'axios';
import swal from "sweetalert";
import parse from 'html-react-parser';
import { Spinner } from "react-bootstrap";
const stripHtmlTags = (html) => {
  const parsedHtml = parse(html);
  const styledHtml = React.Children.map(parsedHtml, (child) => {
    if (React.isValidElement(child)) {
      const style = child.props.style;
      if (style) {

        const styleString = Object.entries(style)
          .map(([key, value]) => `${key}:${value}`)
          .join(';');
        return React.cloneElement(child, { style: styleString });
      }
    }
    return child;
  });

  return styledHtml;
};
const Subscription = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusPriority, setStatusPriority] = useState(tableData);
  const [data, setData] = useState([]);
  const [activePag, setActivePag] = useState(() => {
    const storedPage = sessionStorage.getItem('activePag');
    return storedPage ? parseInt(storedPage) : 0;
  });
  const sort = 10;
  const handleStatus = async (id, status) => {
    const subscribtionStatus = status === "Active" ? true : false; // Convert status to boolean
    console.log("id", id, "subscribtionStatus", subscribtionStatus);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_PAYMENT_API_URI}/subscribtionisactive/${id}`,
        {
          subscribtionStatus: subscribtionStatus
        },
        {
          headers: {
            authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Status Updated!");
      }
    } catch (error) {
      toast.error(error?.response.data);
      console.log(error);
    }
  }

  const axiosWithAuth = axios.create({
    baseURL: `${process.env.REACT_APP_SPORTS_API_URI}/`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const axiosWithAuthPayment = axios.create({
    baseURL: `${process.env.REACT_APP_PAYMENT_API_URI}/`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const handleDelete = (sportId) => {
    swal({
      title: "Are you sure you want to delete?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const token = localStorage.getItem("token");
        console.log("Token:", token);

        axiosWithAuthPayment.defaults.headers.common['Authorization'] = `${token}`;

        const deleteSportUrl = `deletesubscription/${sportId}`;
        console.log('Delete URL:', deleteSportUrl);

        axiosWithAuth.post(deleteSportUrl)
          .then(response => {
            console.log('Delete success:', response.data);
            swal("Your data has been deleted!", {
              icon: "success",
            });

            setStatusPriority(prevState => {
              if (Array.isArray(prevState)) {
                return prevState.filter(item => item.id !== sportId);
              } else {
                console.error('Previous state is not an array:', prevState);
                return prevState;
              }
            });
          })
          .catch(error => {
            console.error('Delete error:', error);
            swal("Error!", "An error occurred while deleting your data.", "error");
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };



  const updateTableData = (newSubscription) => {
    setStatusPriority(prevState => [...prevState, newSubscription]);
  };

  // Handle page click event
  const onClickPage = (page) => {
    setActivePag(page);
    sessionStorage.setItem('activePag', page);
    fetchsubscriptionData(page + 1);
  };

  useEffect(() => {
    fetchsubscriptionData();
  }, []);

  // Calculate pagination
  const pagination = useMemo(() => Array.from({ length: Math.ceil(data.length / sort) }, (_, index) => index + 1), [data]);
  const handlesubscriptionUpdated = () => {
    // Trigger a re-fetch of promo code data from the server
    fetchsubscriptionData();
  };
  const fetchsubscriptionData = async (page = 1) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_PAYMENT_API_URI}/getallsubscriptions?page=${page}`;
    const token = localStorage.getItem('token');

    if (token) {
      axios.get(apiUrl, {
        headers: {
          'authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          setLoading(false);
          setData(response.data.data);
          setTableData(response.data.data);
          setStatusPriority(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching promo code data:', error);
          setLoading(false);
        });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };

  console.log('Status Priority:', statusPriority);
  const employe = useRef();
  const employee = useRef();
  const viewathlete = useRef();
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Spinner className="text-center" animation="border" variant="primary" />
      </div>
    );
  }
  return (
    <>
      <MainPagetitle
        mainTitle="Subscription"
        pageTitle={"Subscription"}
        parentTitle={"Home"}
      />



      <div className="">
        <div className="row">
          <div className="col-xl-12">

            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Subscription plans</h4>
                    <div>

                      <Link
                        to={"#"}
                        className="btn btn-primary btn-sm ms-1"
                        data-bs-toggle="offcanvas"
                        onClick={() => employe.current.showEmployeModal()}
                      >
                        + Add Subscription
                      </Link>{" "}

                    </div>
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div>
                      {tableData.length > 0 ? (
                        // Render the table if tableData has elements
                        <table
                          id="empoloyees-tblwrapper"
                          className="table ItemsCheckboxSec dataTable no-footer mb-0"
                        >
                          <thead>
                            <tr>

                              <th>Name</th>
                              <th>Description</th>
                              {/* <th>Duration</th> */}
                              {/* <th>Profile</th>
                                  <th>Sports</th> */}
                              <th>Subscription Limit</th>
                              <th>Subscription Amount</th>
                              <th>Active Status</th>
                              <th className="d-flex justify-content-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {statusPriority.map((item, index) => (
                              <tr key={index}>
                                <td>{item.subscriptionName}</td>
                                <td className="sport-description-cell">{stripHtmlTags(item.description)}</td>
                                <td>{item.subscriptionLimit}</td>
                                <td>{item.subscriptionAmount}</td>
                                <td className="custom-select-kr">
                                  <select
                                    defaultValue={item.subscribtionStatus ? "Active" : "Inactive"}
                                    className="form-control"
                                    onChange={(e) => handleStatus(item.id, e.target.value)}
                                  >
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                  </select>
                                </td>




                               
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      <div className="p-1">
                                        <Link
                                          to={"#"}
                                          className="btn  btn-sharp"
                                          data-bs-toggle="offcanvas"
                                          onClick={() =>
                                            viewathlete.current.showEmployeModal(item.id)
                                          }
                                        >
                                          <svg
                                            width="15"
                                            height="16"
                                            viewBox="0 0 15 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M0.625 8C0.625 8 3.125 3 7.5 3C11.875 3 14.375 8 14.375 8C14.375 8 11.875 13 7.5 13C3.125 13 0.625 8 0.625 8Z"
                                              stroke="var(--primary)"
                                              strokeWidth="1.2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                            <path
                                              d="M7.5 9.875C8.53553 9.875 9.375 9.03553 9.375 8C9.375 6.96447 8.53553 6.125 7.5 6.125C6.46447 6.125 5.625 6.96447 5.625 8C5.625 9.03553 6.46447 9.875 7.5 9.875Z"
                                              stroke="var(--primary)"
                                              strokeWidth="1.2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></path>
                                          </svg>
                                        </Link>{" "}
                                      </div>
                                      <div className="p-1">
                                        <Link
                                          to={"#"}
                                          className="btn  btn-sharp"
                                          data-bs-toggle="offcanvas"
                                          onClick={(e) => {
                                            e.preventDefault(); // Prevent default link behavior
                                            employee.current.showEmployeModal(item.id);
                                          }}
                                        >
                                          <svg
                                            width="15"
                                            height="16"
                                            viewBox="0 0 15 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M10.625 2.37519C10.7892 2.21104 10.984 2.08082 11.1985 1.99199C11.413 1.90315 11.6429 1.85742 11.875 1.85742C12.1071 1.85742 12.337 1.90315 12.5515 1.99199C12.766 2.08082 12.9608 2.21104 13.125 2.37519C13.2892 2.53934 13.4194 2.73422 13.5082 2.94869C13.597 3.16317 13.6428 3.39304 13.6428 3.62519C13.6428 3.85734 13.597 4.08721 13.5082 4.30168C13.4194 4.51616 13.2892 4.71104 13.125 4.87519L4.6875 13.3127L1.25 14.2502L2.1875 10.8127L10.625 2.37519Z"
                                              stroke="var(--primary)"
                                              strokeWidth="1.2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </Link>{" "}
                                      </div>
                                      <div className="p-1">
                                        <Link
                                          to={"#"}
                                          className="btn  btn-sharp"
                                        >
                                          <button onClick={() => handleDelete(item.id)} className="btn btn-sharp">

                                            <i class="bi bi-archive text-danger"></i>
                                          </button>
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="3">No data available</td>
                          </tr>
                        </tbody>
                      )}
                    </div>

                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing  {activePag + 1} page of {data.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        <Link
                          className={`paginate_button previous ${activePag === 0 ? 'disabled' : ''}`}
                          to=""
                          onClick={(e) => {
                            if (activePag > 0) {
                              onClickPage(activePag - 1);
                            } else {
                              e.preventDefault();
                            }
                          }}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {pagination.map((number, i) => (
                            <Link
                              key={i}
                              to=""
                              className={`paginate_button ${activePag === i ? 'current' : ''}`}
                              onClick={(e) => {
                                e.preventDefault();
                                onClickPage(i);
                              }}
                            >
                              {number}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className={`paginate_button next ${activePag + 1 >= pagination.length ? 'disabled' : ''}`}
                          to=""
                          onClick={(e) => {
                            if (activePag + 1 < pagination.length) {
                              onClickPage(activePag + 1);
                            } else {
                              e.preventDefault();
                            }
                          }}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SubscriptionOffcanvas ref={employe} Title="Add Subscription Plan " onsubscriptionUpdated={handlesubscriptionUpdated} />
      <EditSubscriptionoffcanvas ref={employee} Title="Update State" onsubscriptionUpdated={handlesubscriptionUpdated} />
      <ViewSubscriptionOffcanvas ref={viewathlete} Title="View  Details" />

    </>
  );
};

export default Subscription;
