import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Offcanvas } from "react-bootstrap";
import parse from 'html-react-parser';
import axios from "axios";
import { Spinner, Form } from "react-bootstrap";
import noimage from '../../images/noprofile/noprofiel.png'
const stripHtmlTags = (html) => {
    const parsedHtml = parse(html);
    const styledHtml = React.Children.map(parsedHtml, (child) => {

        if (React.isValidElement(child)) {

            const style = child.props.style;

            if (style) {
                const styleString = Object.entries(style)
                    .map(([key, value]) => `${key}:${value}`)
                    .join(';');
                return React.cloneElement(child, { style: styleString });
            }
        }
        return child;
    });

    return styledHtml;
};

const AthleteTransaction = forwardRef((props, ref) => {
    const [clubDetails, setClubDetails] = useState(null);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [addEmploye, setAddEmploye] = useState(false);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        showEmployeModal(clubId) {
            fetchClubDetails(clubId);
            fetchTransactionHistory(clubId);
            setAddEmploye(true);
        },
        hideEmployeModal() {
            setAddEmploye(false);
        },
    }));

    const fetchClubDetails = (clubId) => {
        console.log(clubId);
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_USER_PROFILE_API_URI}/getathlete/${clubId}`;
        const token = localStorage.getItem("token");

        if (token) {
            axios
                .get(apiUrl, {
                    headers: {
                        authorization: ` ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    console.log("Complete response:", response.data.data);
                    const img = response?.data?.data?.galleryData?.[0]?.fileLocation;

                    const { userData, athleteData, sportData, citiesData, stateData } = response?.data?.data || {};
                    if (userData && athleteData && sportData && citiesData && stateData) {
                        setClubDetails({
                            userData,
                            athleteData,
                            sportData,
                            citiesData,
                            stateData,
                        });
                    } else {
                        console.error("Invalid response data format");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching athlete details:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error("Token not found in local storage");
            setLoading(false);
        }
    };

    const fetchTransactionHistory = (clubId) => {
        setLoading(true);
        const apiUrl = `${process.env.REACT_APP_REPORTS_API_URI}/transactionhistory/${clubId}`;
        const token = localStorage.getItem("token");

        if (token) {
            axios
                .get(apiUrl, {
                    headers: {
                        authorization: ` ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setTransactionHistory(response.data.transactionData);
                })
                .catch((error) => {
                    console.error("Error fetching transaction history:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error("Token not found in local storage");
            setLoading(false);
        }
    };

    return (
        <>
            <Offcanvas
                show={addEmploye}
                onHide={() => setAddEmploye(false)}
                className="offcanvas-end customeoff"
                placement="end"
            >
                {loading ? (
                    <p>Loading...</p>
                ) : clubDetails ? (
                    <>
                        <div className="offcanvas-header profile-head-h">
                            <h5 className="modal-title text-white" id="#gridSystemModal">
                                {props.Title}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setAddEmploye(false)}
                            >
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                        <Offcanvas.Body>
                            <div className="container">
                                {/* <h3 className='profile-head'>Profile Details</h3> */}
                                {/* Profile details content */}
                                <div className="row d-flex justify-content-around">
                                    {/* Club & Academy Name */}
                                    {/* <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p"><span className="view-h">Club & Academy Name:</span> {clubDetails?.academieData?.academieName}</p>
                    </div> */}
                                    {/* Other profile details */}
                                    {/* Transaction History */}
                                    <h3 className='profile-head'>{clubDetails?.userData?.firstName}-Transaction History</h3>
                                    {transactionHistory.length > 0 ? (
                                        transactionHistory.map((transaction, index) => (
                                            <div key={transaction.id} className="col-xl-6 mb-3 view-detail-table">
                                                <h5 className='profile-head'>Transaction Detail {index + 1}</h5>
                                                <div className="">
                                                    <div className="col-lg-12">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">ID</th>
                                                                    <td>{transaction.id}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Subscription Plan</th>
                                                                    <td>{transaction.subscription.subscriptionName}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Transaction ID</th>
                                                                    <td>{transaction.paymentTransactionId}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Payment Status</th>
                                                                    <td>{transaction.paymentStatus}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th scope="row">Tax Amount</th>
                                                                    <td>{transaction.taxAmount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Processing Fee</th>
                                                                    <td>{transaction.processingTax}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Convenience Fee</th>
                                                                    <td>{transaction.convenienceTax}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Service Fee</th>
                                                                    <td>{transaction.serviceTax}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Tax Percentage</th>
                                                                    <td>{transaction.taxPercentage}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Discount</th>
                                                                    <td>{transaction.discount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Mode of Payment</th>
                                                                    <td>{transaction.modeOfPayment}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Date of Payment</th>
                                                                    <td>{transaction.startDate}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">End Date of Plan</th>
                                                                    <td>{transaction.endDate}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Subtotal Amount</th>
                                                                    <td>{transaction.subtotalAmount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th scope="row">Total Amount</th>
                                                                    <td>{transaction.totalAmount}</td>
                                                                </tr>





                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No transaction history available.</p>
                                    )}
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </>
                ) : (
                    <p>No data available for the club</p>
                )}

            </Offcanvas>
        </>
    );
});

export default AthleteTransaction;

