import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import axios from "axios";

import { Spinner, Form } from "react-bootstrap";

const ViewSportOffcanvas = forwardRef((props, ref) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [addEmploye, setAddEmploye] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const nav = useNavigate();

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []); // Empty dependency array means it only runs once

  useImperativeHandle(ref, () => ({
    showEmployeModal(Id) {
      setAddEmploye(true);
      fetchData(Id);
    },
  }));

  const fetchData = (Id) => {
    console.log("Id",Id);
    setLoading(true)
    const apiUrl = `${process.env.REACT_APP_STATE_TAX_API_URI}/getbillingstate/${Id}`;
    const token = localStorage.getItem('token');
    if (token) {
      axios.get(apiUrl, {
        headers: {
          'authorization': ` ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          setData(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching sport details:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };
    
  const handleSubmit = (e) => {
    e.preventDefault();
    nav("#");
  };

  return (
    <>
      <Offcanvas
        show={addEmploye}
        onHide={() => setAddEmploye(false)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        <div className="offcanvas-header">
          <h5 className="modal-title" id="#gridSystemModal">
            {props.Title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddEmploye(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          {loading ? (
            <p>        
              <Spinner className="text-center" animation="border" variant="primary" />
            </p>
          ) : data ? (
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
              <div className="row d-flex justify-content-around">
                  <div className="col-xl-5 mb-3 view-detail-table">
                    <p>State: {data.stateName}</p> {/* Assuming 'state' is a key in your fetched data */}
                  </div>
                  <div className="col-xl-5 mb-3 view-detail-table">
                    <p>Tax: {data.tax}</p> {/* Assuming 'tax' is a key in your fetched data */}
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <p>No data available.</p>
          )}
        </div>
      </Offcanvas>
    </>
  );
});

export default ViewSportOffcanvas;
