import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Offcanvas } from "react-bootstrap";
import parse from 'html-react-parser';
import axios from "axios";
import { Spinner, Form } from "react-bootstrap";
import noimage from '../../images/noprofile/noprofiel.png'
const stripHtmlTags = (html) => {
  const parsedHtml = parse(html);
  const styledHtml = React.Children.map(parsedHtml, (child) => {

    if (React.isValidElement(child)) {

      const style = child.props.style;

      if (style) {
        const styleString = Object.entries(style)
          .map(([key, value]) => `${key}:${value}`)
          .join(';');
        return React.cloneElement(child, { style: styleString });
      }
    }
    return child;
  });

  return styledHtml;
};

const ViewClubOffcanvas = forwardRef((props, ref) => {
  const [clubDetails, setClubDetails] = useState(null);
  const [addEmploye, setAddEmploye] = useState(false);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    showEmployeModal(clubId) {
      fetchClubDetails(clubId);
      setAddEmploye(true);
    },
    hideEmployeModal() {
      setAddEmploye(false);
    },
  }));
  const fetchClubDetails = (clubId) => {
    console.log(clubId);
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_USER_PROFILE_API_URI}/getacademies/${clubId}`;
    const token = localStorage.getItem("token");

    if (token) {
      axios
        .get(apiUrl, {
          headers: {
            authorization: ` ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("Complete response:", response.data.data);
          const img = response?.data?.data?.galleryData?.[0]?.fileLocation;  // Ensure galleryData array is not empty
        
          const { userData, academieData, sportData, citiesData, stateData } = response?.data?.data || {}; // Destructure userData, coachData, sportData, citiesData, and stateData
          if (userData && academieData && sportData && citiesData && stateData) {
            // Find city and state based on stateId and id respectively
          
            setClubDetails({
              userData, // Spread userData properties
              academieData,
              sportData,
              citiesData, // Spread citiesData properties
              stateData,  // Spread sportData properties
            });

          } else {
            console.error("Invalid response data format");
          }
        })
        .catch((error) => {
          console.error("Error fetching athlete details:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.error("Token not found in local storage");
      setLoading(false);
    }
  };
  return (
    <>
      <Offcanvas
        show={addEmploye}
        onHide={setAddEmploye}
        className="offcanvas-end customeoff"
        placement="end"
      >
        {loading ? (
          <p>Loading...</p>
        ) : clubDetails ? (
          <>
            <div className="offcanvas-header profile-head-h">
              <h5 className="modal-title text-white" id="#gridSystemModal">
                {props.Title}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setAddEmploye(false)}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <Offcanvas.Body>
              {loading ? (
                <p>
                  <Spinner className="text-center" animation="border" variant="primary" />
                </p>
              ) : clubDetails ? (
                <>
                  <div className="container">
                    <form>

                      <div className="row d-flex justify-content-around">
                        <h3 className='profile-head'>Profile Details</h3>

                        <div className="col-xl-12 mb-3 view-detail-table">
                          {clubDetails.galleryData && clubDetails.galleryData.length > 0 ? (
                            clubDetails.galleryData.map((imageItem) => (
                              <div key={imageItem.id} className="col-xl-12 mb-3 p-2">
                                <img
                                  src={imageItem.fileLocation}
                                  alt={imageItem.description || 'club-image'}  // optional: provide a meaningful alt if available
                                  className="p-2"
                                />
                              </div>
                            ))
                          ) : (
                            <div className="col-xl-12 mb-3 p-2">
                              <img src={noimage} alt="noimage"/> 
                            </div>
                          )}
                        </div>

                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Club & Academy Name:</span> {clubDetails?.academieData?.academieName}</p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Contact Title:</span> {clubDetails.academieData.title}</p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Contact First Name:</span>{clubDetails.userData.firstName}</p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Contact Last Name:</span>{clubDetails.userData.lastName}</p>
                        </div>

                       
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Email:</span> {clubDetails.userData.email}</p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Phone Number:</span> {clubDetails.academieData.phone}</p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">City You Interested In:</span>
                        {console.log('citiesData:', clubDetails?.citiesData)}
                        {clubDetails?.citiesData && clubDetails.citiesData.map((cityItem, cityIndex) => (
                          <span key={cityIndex}>
                            {cityIndex > 0 && ', '}
                            {cityItem?.name}
                          </span>
                        ))}
                      </p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p className="view-p">
                        <span className="view-h">State You Interested In:</span>
                        {clubDetails?.stateData && clubDetails.stateData.map((stateItem, stateIndex) => (
                          <span key={stateIndex}>
                            {stateIndex > 0 && ', '}
                            {stateItem?.name}
                          </span>
                        ))}
                      </p>
                    </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Instagram:</span> {clubDetails.academieData.instagramLink}</p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Twitter:</span> {clubDetails.academieData.twitterLink}</p>
                        </div>
                        <div className="col-xl-12 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Website:</span> {clubDetails.academieData.websiteLink}</p>
                        </div>
                        <div className="col-xl-12 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Description:</span>{stripHtmlTags(clubDetails.academieData.bio)}</p>
                        </div>
                        <h3 className='profile-head'>Sport Details</h3>

                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Primary Sport:</span> {clubDetails.sportData.sportName}</p>
                        </div>
                        
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">League Info:</span> {clubDetails.academieData.leagueName}</p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Age You Interested In:</span> {clubDetails.academieData.ageYouCoach}</p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p"><span className="view-h">Gender You Interested In:</span> {clubDetails.academieData.genderYouCoach}</p>
                        </div>
                        
                        

                        <h3 className='profile-head'>Profile Status</h3>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p">
                            <span className="view-h">Subscription Status:</span>
                            {clubDetails?.subscriptionData
                              ? clubDetails.subscriptionData.subscribtionStatus
                                ? clubDetails.subscriptionData.subscribtionStatus
                                : "Not Defined"
                              : "Not Defined"}
                          </p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p">
                            <span className="view-h">Approval Status:</span>
                            {clubDetails.academieData.isApprove}
                          </p>
                        </div>
                        <div className="col-xl-12 mb-3 view-detail-table">
                          <p className="view-p">
                            <span className="view-h">Publish Status:</span>
                            {clubDetails.isActive === true ? "Published" : "Unpublished"}
                          </p>
                        </div>



                        <h3 className='profile-head'>Subscription Details</h3>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p">
                            <span className="view-h">Subscription Name:</span>
                            {clubDetails?.subscriptionData
                              ? clubDetails.subscriptionData.subscriptionName
                                ? clubDetails.subscriptionData.subscriptionName
                                : "Not Defined"
                              : "Not Defined"}
                          </p>
                        </div>

                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p">
                            <span className="view-h">Subscription Amount:</span>
                            {clubDetails?.subscriptionData
                              ? clubDetails.subscriptionData.subscriptionAmount
                                ? clubDetails.subscriptionData.subscriptionAmount
                                : "Not Defined"
                              : "Not Defined"}
                          </p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p">
                            <span className="view-h">Processing Fee:</span>
                            {clubDetails?.subscriptionData
                              ? clubDetails.subscriptionData.processingTax
                                ? clubDetails.subscriptionData.processingTax
                                : "Not Defined"
                              : "Not Defined"}
                          </p>
                        </div>
                        <div className="col-xl-6 mb-3 view-detail-table">
                          <p className="view-p">
                            <span className="view-h">Convenience Fee:</span>
                            {clubDetails?.subscriptionData
                              ? clubDetails.subscriptionData.convenienceTax
                                ? clubDetails.subscriptionData.convenienceTax
                                : "Not Defined"
                              : "Not Defined"}
                          </p>
                        </div>
                        <div className="col-xl-12 mb-3 view-detail-table">
                          <p className="view-p">
                            <span className="view-h">Service Fee:</span>
                            {clubDetails?.subscriptionData
                              ? clubDetails.subscriptionData.serviceTax
                                ? clubDetails.subscriptionData.serviceTax
                                : "Not Defined"
                              : "Not Defined"}
                          </p>
                        </div>

                      </div>



                    </form>
                  </div>
                </>
              ) : (
                <p>No data available for the athlete</p>
              )}
            </Offcanvas.Body>
          </>
        ) : (
          <p>No data available for the athlete</p>
        )}
      </Offcanvas >
    </>
  );
});

export default ViewClubOffcanvas;
