import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import MainPagetitle from "../../layouts/MainPagetitle";
import SportOffcanvas from "../../constant/SportOffcanvas";
import EditsportOffcanvas from "../../constant/EditSportOffcanvas";
import ViewSportOffcanvas from "../../constant/ViewSportOffcanvas";
import swal from "sweetalert";
import axios from 'axios';
import noimage from '../../../images/noprofile/noprofiel.png'
import { Spinner } from "react-bootstrap";
const Sport = () => {

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusPriority, setStatusPriority] = useState(tableData);

  const [data, setData] = useState();

  const sort = 10;
  const [activePag, setActivePag] = useState(() => {
    const storedPage = sessionStorage.getItem('activePag');
    return storedPage ? parseInt(storedPage) : 0;
  });


  // Handle page click event
  const onClickPage = (page) => {
    setActivePag(page);
    sessionStorage.setItem('activePag', page);
    fetchsportData(page + 1);
  };

  // useEffect to fetch athlete data on component mount
  useEffect(() => {
    fetchsportData();
  }, []);

  // Calculate pagination
  const pagination = useMemo(() => {
    if (!data) return []; // Handle undefined data
    return Array.from({ length: Math.ceil(data.length / sort) }, (_, index) => index + 1);
  }, [data, sort]);
  // Create an axios instance with default headers
  const axiosWithAuth = axios.create({
    baseURL: `${process.env.REACT_APP_SPORTS_API_URI}/`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });



  const handleDelete = (sportId) => {
    swal({
      title: "Are you sure you want to delete?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const token = localStorage.getItem("token");
        axiosWithAuth.defaults.headers.common['Authorization'] = `${token}`;
        axiosWithAuth.post(`deletesport/${sportId}`)
          .then(response => {
            swal("Your data has been deleted!", {
              icon: "success",
            });
            setTableData(prevState => prevState.filter(item => item.id !== sportId));
          })
          .catch(error => {
            console.error('Delete error:', error);
            swal("Error!", "An error occurred while deleting your data.", "error");
          });
      } else {
        swal("Your data is safe!");
      }
    });
  };




  const handlesportUpdated = () => {

    fetchsportData();
  };
  const fetchsportData = (page = 1) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_SPORTS_API_URI}/getallsports?page=${page}`;
    const token = localStorage.getItem('token');

    if (token) {
      axios.get(apiUrl, {
        headers: {
          'authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          setLoading(false);
          setData(response.data.data);
          setTableData(response.data.data);
          setStatusPriority(response.data.data);
        })
        .catch(error => {
          console.error('Error fetching sport data:', error);
          setLoading(false);
        });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };



  const employe = useRef();
  const editsport = useRef();
  const viewathlete = useRef();
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Spinner className="text-center" animation="border" variant="primary" />
      </div>
    );
  }
  return (
    <>
      <MainPagetitle
        mainTitle="Sport"
        pageTitle={"Sport"}
        parentTitle={"Home"}
      />


      <div className="">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                    <h4 className="heading mb-0">Sports List</h4>
                    <div>

                      <Link
                        to={"#"}
                        className="btn btn-primary btn-sm ms-1"
                        data-bs-toggle="offcanvas"
                        onClick={() => employe.current.showEmployeModal()}
                      >
                        + Add sport
                      </Link>{" "}

                    </div>
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div>
                      {tableData.length > 0 ? (
                        <table
                          id="empoloyees-tblwrapper"
                          className="table ItemsCheckboxSec dataTable no-footer mb-0"
                        >
                          <thead>
                            <tr>

                              <th>Sport Logo</th>
                              <th>Sport Name</th>
                              <th>sport Description</th>

                              <th className="d-flex justify-content-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((item, index) => (
                              <tr key={index}>
                                <td>

                                  <img
                                    src={item?.sportLogo || noimage}
                                    className="avatar avatar-md"
                                    alt={item.sportName || "No Image Available"}
                                    onError={(e) => { e.target.src = noimage; }}  
                                  />
                                </td>



                                <td>
                                  <span>{item.sportName}</span> 
                                </td>
                                <td className="sport-description-cell">
                                  <span>{item.sportDescription}</span>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center">

                                    <div className="p-1">
                                      <Link
                                        to={"#"}
                                        className="btn  light btn-sharp"
                                        data-bs-toggle="offcanvas"
                                        onClick={(event) => {
                                          event.preventDefault(); // Prevent the default behavior
                                          editsport.current.showEmployeModal(item.id);
                                        }}
                                      >
                                        <svg
                                          width="15"
                                          height="16"
                                          viewBox="0 0 15 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M10.625 2.37519C10.7892 2.21104 10.984 2.08082 11.1985 1.99199C11.413 1.90315 11.6429 1.85742 11.875 1.85742C12.1071 1.85742 12.337 1.90315 12.5515 1.99199C12.766 2.08082 12.9608 2.21104 13.125 2.37519C13.2892 2.53934 13.4194 2.73422 13.5082 2.94869C13.597 3.16317 13.6428 3.39304 13.6428 3.62519C13.6428 3.85734 13.597 4.08721 13.5082 4.30168C13.4194 4.51616 13.2892 4.71104 13.125 4.87519L4.6875 13.3127L1.25 14.2502L2.1875 10.8127L10.625 2.37519Z"
                                            stroke="var(--primary)"
                                            strokeWidth="1.2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </Link>{" "}
                                    </div>
                                    <div className="p-1">
                                      <Link
                                        to={"#"}
                                        className="btn  btn-sharp"
                                      >
                                        <button onClick={() => handleDelete(item.id)} className="btn btn-sharp">
                                          <svg
                                            width="15"
                                            height="16"
                                            viewBox="0 0 15 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1.875 4.25H3.125H13.125"
                                              stroke="#FF5B5B"
                                              strokeWidth="1.2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M11.875 4.25V13C11.875 13.3315 11.7433 13.6495 11.5089 13.8839C11.2745 14.1183 10.9565 14.25 10.625 14.25H4.375C4.04348 14.25 3.72554 14.1183 3.49112 13.8839C3.2567 13.6495 3.125 13.3315 3.125 13V4.25M5 4.25V3C5 2.66848 5.1317 2.35054 5.36612 2.11612C5.60054 1.8817 5.91848 1.75 6.25 1.75H8.75C9.08152 1.75 9.39946 1.8817 9.63388 2.11612C9.8683 2.35054 10 2.66848 10 3V4.25"
                                              stroke="#FF5B5B"
                                              strokeWidth="1.2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M6.25 7.375V11.125"
                                              stroke="#FF5B5B"
                                              strokeWidth="1.2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                            <path
                                              d="M8.75 7.375V11.125"
                                              stroke="#FF5B5B"
                                              strokeWidth="1.2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </button>
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="3">No data available</td>
                          </tr>
                        </tbody>
                      )}
                    </div>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing page {activePag + 1}  of {data.length} entries
                      </div>
                      <div className="dataTables_paginate paging_simple_numbers" id="example2_paginate">
                        <Link
                          className={`paginate_button previous ${activePag === 0 ? 'disabled' : ''}`}
                          to=""
                          onClick={(e) => {
                            if (activePag > 0) {
                              onClickPage(activePag - 1);
                            } else {
                              e.preventDefault();
                            }
                          }}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {pagination.map((number, i) => (
                            <Link
                              key={i}
                              to=""
                              className={`paginate_button ${activePag === i ? 'current' : ''}`}
                              onClick={(e) => {
                                e.preventDefault();
                                onClickPage(i);
                              }}
                            >
                              {number}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className={`paginate_button next ${activePag + 1 >= pagination.length ? 'disabled' : ''}`}
                          to=""
                          onClick={(e) => {
                            if (activePag + 1 < pagination.length) {
                              onClickPage(activePag + 1);
                            } else {
                              e.preventDefault();
                            }
                          }}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <SportOffcanvas onSportAdded={handlesportUpdated} ref={employe} Title="Add Sport"  />
      <EditsportOffcanvas ref={editsport} Title="Update Sport" onsportUpdated={handlesportUpdated} />
      <ViewSportOffcanvas ref={viewathlete} Title="View Sport Details" />

    </>
  );
};

export default Sport;
