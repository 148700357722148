import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Offcanvas } from "react-bootstrap";
import axios from 'axios';
import parse from 'html-react-parser';
import { Spinner, Form } from "react-bootstrap";

const stripHtmlTags = (html) => {
  const parsedHtml = parse(html);
  const styledHtml = React.Children.map(parsedHtml, (child) => {

    if (React.isValidElement(child)) {

      const style = child.props.style;

      if (style) {
        const styleString = Object.entries(style)
          .map(([key, value]) => `${key}:${value}`)
          .join(';');
        return React.cloneElement(child, { style: styleString });
      }
    }
    return child;
  });
  
  return styledHtml;
};
const ViewSportOffcanvas = forwardRef((props, ref) => {
  const [promoCodeDetails, setPromoCodeDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    showEmployeModal(promoId) {
      fetchPromoCodeDetails(promoId);
    },
  }));

  const fetchPromoCodeDetails = (promoId) => {
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_PAYMENT_API_URI}/getpromocode/${promoId}`;
    const token = localStorage.getItem('token');

    if (token) {
      axios.get(apiUrl, {
        headers: {
          'authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setPromoCodeDetails(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching promo code details:', error);
      })
      .finally(() => {
        setLoading(false);
      });
    } else {
      console.error('Token not found in local storage');
      setLoading(false);
    }
  };

  return (
    <>
      <Offcanvas
        show={promoCodeDetails !== null}
        onHide={() => setPromoCodeDetails(null)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        <div className="offcanvas-header profile-head-h">
          <h5 className="modal-title text-white" id="#gridSystemModal">
            {props.Title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setPromoCodeDetails(null)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
          {loading ? (
            <p>        
              <Spinner className="text-center" animation="border" variant="primary" />
            </p>
          ) : (
              <div>
               {!loading && promoCodeDetails && (
                  <div  className="row d-flex justify-content-around">
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p><span className="view-h">Promo code value:</span> {promoCodeDetails.promocodeName}</p>
                    </div>
                    <div className="col-xl-5 mb-3 view-detail-table">
                    <p><span className="view-h">Profile:</span> {promoCodeDetails.role?.roleName}</p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p><span className="view-h">Sport: </span>{promoCodeDetails.sport?.sportName}</p> {/* Assuming you have a mapping for sport IDs */}
                    </div>
                    <div className="col-xl-5 mb-3 view-detail-table">
                      <p><span className="view-h">Discount:</span> {promoCodeDetails.discount}%</p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p><span className="view-h">Subscription Limit:</span> {promoCodeDetails.accessLimit}</p>
                    </div>
                    <div className="col-xl-5 mb-3 view-detail-table">
                      <p><span className="view-h">Start Date:</span> {promoCodeDetails.startDate}</p>
                    </div>
                    <div className="col-xl-6 mb-3 view-detail-table">
                      <p><span className="view-h">End Date:</span> {promoCodeDetails.endDate}</p>
                    </div>
                    <div className="col-xl-5 mb-3 view-detail-table">
                      <p><span className="view-h">Status:</span> {promoCodeDetails.isEnable ? 'Active' : 'Inactive'}</p>
                    </div>
                    <div className="col-xl-12 mb-3 view-detail-table">
                   
                      <p><span className="view-h">Description:</span> {stripHtmlTags(promoCodeDetails.promocodeDescription)}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

      </Offcanvas>
    </>
  );
});

export default ViewSportOffcanvas;
