import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { toast } from 'react-toastify';
const ClubOffcanvas = forwardRef((props, ref) => {
  const [addEmploye, setAddEmploye] = useState(false);
  const [sportData, setSportData] = useState([]);
  const [ClubData, setClubData] = useState([]);
  const [profileFile, setprofileFile] = useState(null);
  const [sports, setSports] = useState([]);
  const [selectedsportsId, setSelectedsportsId] = useState('');
  const [subscription, setSubscription] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [selectedCityIds, setSelectedCityIds] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState('');
  const [cities, setCities] = useState([]);
  const [state, setState] = useState([]);
  const [athleteSpecialties, setAthleteSpecialties] = useState([]);
  const [showSelectInput, setShowSelectInput] = useState(true); // Initially set to true or false based on your requirement

  const initialValues = {
    academieName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    profileImg: '',
    city: '',
    state: '',
    phone: '',
    title: '',
    bio: '',
    leagueName: '',
    ageYouCoach: '',
    genderYouCoach: '',
    instagramLink: '',
    websiteLink: '',
    twitterLink: '',
    sportsId: '',
    subscriptionId: '23',
    // isApprove: "",
    // isPublish: "",
  };

  const validate = (values) => {
    const errors = {};
    //  Profile
    //  if (!values.profileImg) {
    //   errors.profileImg = 'Profile picture is required';
    // }
    if (!values.academieName) {
      errors.academieName = 'Required';
    } else if (values.academieName.length > 25) {
      errors.academieName = 'Too Long';
    }

    if (!values.firstName) {
      errors.firstName = 'Required';
    } else if (values.firstName.length > 15) {
      errors.firstName = 'Too Long';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    } else if (values.lastName.length > 15) {
      errors.lastName = 'Too Long';
    }

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email format';
    }


    if (!values.city) {

    }

    if (!values.state) {

    }

    if (!values.phone) {
      errors.phone = 'Required';
    } else if (isNaN(values.phone) || values.phone.toString().length !== 10) {
      errors.phone = 'Phone number must be of 10 digits';
    }

    if (!values.title) {
      errors.title = 'Required';
    }

    if (!values.bio) {
      const wordCount = values.bio.trim().split(/\s+/).length;
      console.log(wordCount);

      if (wordCount > 1000) {
        errors.bio = ' Cannot exceed 1000 words';
      }
    }



    if (!values.ageYouCoach) {
      errors.ageYouCoach = 'Required';
    }

    if (!values.genderYouCoach) {
      errors.genderYouCoach = 'Required';
    }



    if (!values.sportsId) {
      errors.sportsId = 'Required';
    }

    if (!values.subscriptionId) {
      errors.subscriptionId = 'Required';
    }
    console.log("errors", errors)

    return errors;
  };

  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmploye(true);
    },
  }));

  //render
  useEffect(() => {
    fetchAge();
    fetchclubData();
    fetchSports();
    fetchAge();
    fetchStates();

  }, []);
  const handleStateChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map(option => option.value);
    setSelectedStateId(selectedIds);
    console.log('Selected State IDs:', selectedIds);
  };

  // Modify the handleCityChange function to handle multi-select for cities
  const handleCityChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map(option => option.value);
    setSelectedCityIds(selectedIds);
    console.log('Selected City IDs:', selectedIds);
  };
  const fetchStates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_STATE_TAX_API_URI}/getallstates`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      setState(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };
  useEffect(() => {

    setCities([]);
    if (selectedStateId) {
      fetchCitiesByState(selectedStateId);
    }
  }, [selectedStateId]);
  console.log(selectedStateId);
  const fetchCitiesByState = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_STATE_TAX_API_URI}/getallcities?stateId=${id}`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });
      console.log("city", response.data.citiesData);
      if (response.data.citiesData && response.data.citiesData.length > 0) {
        // Flatten the citiesData array directly
        const citiesData = response.data.citiesData.flat().map(city => ({
          value: city.id,
          label: city.name
        }));

        setCities(citiesData);
      } else {
        console.error('Error fetching cities: citiesData is undefined or empty');
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };
  const fetchclubData = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${process.env.REACT_APP_USER_PROFILE_API_URI}/getallacademies`, {
        headers: {
          'authorization': `${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      setClubData(response.data.data); // Update promo code data state with fetched data
    } catch (error) {
      console.error('Error fetching promo codes:', error);
    }
  };

  //CLUB data
  const handleSubmit = async (values) => {

    const stateString = selectedStateId.join(',');
    const cityString = selectedCityIds.join(',');
    const sportsIdString = selectedsportsId.join(',');

    console.log("city", cityString);
    console.log("Submitting form with values:", values);
    const submit = async () => {
      values.ageYouCoach = String(values.ageYouCoach);
      values.genderYouCoach = String(values.genderYouCoach);
      values.city = String(cityString);
      values.state = String(stateString);
      values.sportsId = String(sportsIdString);

      try {
        const token = localStorage.getItem("token");
        let config = {
          headers: {
            Authorization: `${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        const response = await axios.post(
          `${process.env.REACT_APP_USER_PROFILE_API_URI}/admin-addacademies`,
          values,
          config
        );
        if (response.status === 201) {
          const userId = response.data.data.id; // Extracting the ID of the newly created athlete
          console.log(userId);
          // Call handleProfileUpload after admin-addathlete API succeeds


          setAddEmploye(false);
          console.log('Response:', response.data);
          props.onClubAdded();
          toast.success(response.data.message);
          await handleProfileUpload(userId);
          fetchclubData();
        } else {
          // Handle non-200 status codes here

          console.error('Error:', response.status);
        }



      } catch (error) {
        toast.error(error?.response?.data?.message);
        console.error(error.response.data.message);
      }
    }
    submit()
  };
  console.log("sportData", sportData);
  const handleProfileUpload = async (userId) => {
    try {
      const formData = new FormData();
      formData.append('fileLocation', profileFile);
      formData.append('description', "Profile Imahe");
      formData.append('isActive', "true");
      formData.append('fileType', "Profile Image");
      formData.append('isApproved', "true");
      formData.append('userId', userId);
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_CONTENT_API_URI}/upload-singlefile`, formData, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      return response.data.data.id;

    } catch (error) {
      console.error('Error uploading  Profile:', error);
      throw new Error('Error uploading  Profile');
    }
  };
  //sport data
  const fetchSports = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_SPORTS_API_URI}/getallsports`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      setSports(response.data.data);
      console.log(response.data.data)
    } catch (error) {
      console.error('Error fetching sports:', error);
    }
  };

  const fetchAge = async () => {
    try {
      const token = localStorage.getItem('token');
      const id = localStorage.getItem('id');
      console.log(id);
      const response = await axios.get(`${process.env.REACT_APP_USER_PROFILE_API_URI}/getsettings/1`, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'multipart/form-data',
          'authorization': `${token}`,
        }
      });

      const fromAge = parseInt(response.data.data.fromAge); // Ensure fromAge is parsed as an integer
      const toAge = parseInt(response.data.data.toAge); // Ensure toAge is parsed as an integer

      const options = [];
      for (let i = fromAge; i < toAge; i++) {
        options.push({ value: i.toString(), label: i.toString() }); // Create object with value and label properties
      }

      // Push maxAge as the last option
      options.push({ value: response.data.data.toAge.toString(), label: response.data.data.toAge.toString() });

      setAgeOptions(options);
    } catch (error) {
      console.error('Error fetching age:', error);
    }
  };
  const handleProfileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'];
      const maxFileSize = 200 * 1024; // 200 KB in bytes

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        console.log("Invalid file type. Please upload a JPEG, JPG, PNG, or SVG file.");
        toast.error("Invalid file type. Please upload a JPEG, JPG, PNG, or SVG file.");
        e.target.value = null;
        setPreviewImage(null);
        return;
      }

      // Check if the file size exceeds the limit
      if (file.size >= maxFileSize) {
        console.log("File size exceeds the limit of 200 KB.");
        toast.error("File size exceeds the limit of 200 KB.");
        e.target.value = null; // Reset the file input field
        setPreviewImage(null); // Clear the preview image
        return;
      }

      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        if (width >= 290 && width <= 300 && height >= 290 && height <= 300) {
          // If dimensions are within the allowed range
          console.log("Selected file:", file);
          setprofileFile(file);

          const reader = new FileReader();
          reader.onloadend = () => {
            setPreviewImage(reader.result);
          };
          reader.readAsDataURL(file);
        } else {
          // If dimensions are not within the allowed range
          console.log("Image dimensions should be between 290 and 300 pixels in both height and width.");
          toast.error("Image dimensions should be between 290 and 300 pixels in both height and width.");
          e.target.value = null;
          setPreviewImage(null);
        }
      };
      img.src = URL.createObjectURL(file);
    } else {
      console.log("No file selected.");
      setprofileFile(null);
      setPreviewImage(null);
    }
  };

  const [previewImage, setPreviewImage] = useState(null);

  return (
    <>
      <Offcanvas
        show={addEmploye}
        onHide={() => setAddEmploye(false)}
        className="offcanvas-end customeoff"
        placement="end"
      >
        <div className="offcanvas-header profile-head-h">
          <h3 className="modal-title text-white" id="#gridSystemModal">
            {props.Title}
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddEmploye(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={(values) => {

                handleSubmit(values);
                console.log(values);

              }}
              enableReinitialize={true}
            >

              {({
                isSubmitting,
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                values,
                touched,
                errors
              }) => (
                <form onSubmit={handleSubmit}>

                  <div className="col-xl-6 mb-3">
                    <label>Profile Picture</label>
                    {previewImage && (
                      <div>
                        <img src={previewImage} className='mb-3' alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                      </div>
                    )}
                    <div className="dz-default dlab-message upload-img mb-3">

                      <input
                        type='file'
                        id='profileImg'
                        name='profileImg'
                        onChange={handleProfileChange}
                        className='form-control'
                      />
                      <p className='text-danger'>{errors.profileImg}</p>
                      <p className="text-danger">Height & Width should be between 290 - 300  </p>
                      <p className="text-danger">only GIF , JPG , JPEG, PNG, or SVG images up to 200 KB. </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12 mb-3">
                      <label htmlFor="academieName" className="form-label">
                        Club & Academy Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="academieName"
                        className="form-control"
                        id="academieName"
                        placeholder="Club & Academy Name"
                      />
                      <p className='text-danger'>{errors.academieName}</p>

                    </div>


                    <div className="col-xl-6 mb-3">
                      <label htmlFor="title" className="form-label">
                        Contact Title <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        placeholder=" Contact Title"
                      />
                      <p className='text-danger'>{errors.title}</p>

                    </div>
                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="email"
                        className="form-label"
                      >
                        {" "}
                        Email <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                      />
                      <p className='text-danger'>{errors.email}</p>

                    </div>

                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="firstName"
                        className="form-label"
                      >
                        Contact First Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="firstName"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                      />
                      <p className='text-danger'>{errors.firstName}</p>

                    </div>
                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="lastName"
                        className="form-label"
                      >
                        Contact Last Name <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name"
                      />
                      <p className='text-danger'>{errors.lastName}</p>

                    </div>

                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="phone"
                        className="form-label"
                      >

                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <Field
                        type="phone"
                        name="phone"
                        className="form-control"
                        id="phone"
                        placeholder="Phone Number"
                      />
                      <p className='text-danger'>{errors.phone}</p>

                    </div>



                    <div className="col-xl-6 mb-3">
                      <label htmlFor="twitterLink" className="form-label">
                        X Profile Link
                      </label>
                      <Field
                        type="text"
                        name="twitterLink"
                        className="form-control"
                        id="twitterLink"
                        placeholder=" X Profile Link"
                      />
                      <p className='text-danger'>{errors.twitterLink}</p>

                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="instagramLink" className="form-label">
                        Instagram Profile Link

                      </label>
                      <Field
                        type="text"
                        name="instagramLink"
                        className="form-control"
                        id="instagramLink"
                        placeholder="Instagram Profile Link"
                      />
                      <p className='text-danger'>{errors.instagramLink}</p>

                    </div>
                    <div className="col-xl-6 mb-3">
                      <label
                        htmlFor="exampleFormControlInput4"
                        className="form-label"
                      >
                        Website Link
                      </label>
                      <Field
                        type="text"
                        name="websiteLink"
                        className="form-control"
                        id="websiteLink"
                        placeholder="Website Link"
                      />
                      <p className='text-danger'>{errors.websiteLink}</p>

                    </div>
                    <div className="col-xl-12 mb-3">
                      <label className="form-label">Description</label>
                      <Field name="bio">
                        {({ field, form }) => (
                          <div>
                            <CKEditor
                              editor={ClassicEditor}
                              data={field.value}
                              onChange={(event, editor) => {
                                form.setFieldValue(field.name, editor.getData());
                              }}
                            />
                            <p className='text-danger'>{errors.bio}</p>
                          </div>
                        )}
                      </Field>

                    </div>
                    <h3 className='profile-head'>Sport Details</h3>


                    <div className="col-xl-6 mb-3">
                      <label htmlFor="sportsId" className="form-label">
                        Sports of Interest <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="sportsId"
                        options={sports.map((sport) => ({
                          value: sport.id,
                          label: sport.sportName,
                        }))}
                        classNamePrefix="react-select"
                        isMulti
                        id="sportsId"
                        onChange={(option) => {
                          const selectedSportsIds = option ? option.map((opt) => opt.value) : [];
                          setFieldValue("sportsId", selectedSportsIds);
                        }}
                        value={
                          values.sportsId
                            ? values.sportsId.map((id) => ({
                              value: id,
                              label: sports.find((sport) => sport.id === id)?.sportName,
                            }))
                            : []
                        }
                      />
                      <p className='text-danger'>{errors.sportsId}</p>
                    </div>

                    <div className="col-xl-6 mb-3">
                      {/* <label htmlFor="athleteSpecialty" className="form-label">
                      Speciality of Interest
                    </label>
                    <Select
                      name="athleteSpecialty"
                      options={athleteSpecialties.map(specialty => ({
                        value: specialty.id,
                        label: specialty.specialityTitle
                      }))}
                      classNamePrefix="react-select"
                      isMulti
                      id="athleteSpecialty"
                      onChange={(selectedOption) => {
                        const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
                        setFieldValue('athleteSpecialty', selectedValues.join(','));
                      }}
                    />
                    <p className="text-danger">{errors.athleteSpecialty}</p>
                   */}
                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="ageYouCoach" className="form-label">
                        Age You Interested In <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="ageYouCoach"
                        options={ageOptions}
                        isMulti
                        classNamePrefix="select"
                        onChange={(selectedOption) => {
                          const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
                          setFieldValue('ageYouCoach', selectedValues.join(','));
                        }}
                      />
                      <p className='text-danger'>{errors.ageYouCoach}</p>

                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="genderYouCoach" className="form-label">
                        Gender You Interested In <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="genderYouCoach"
                        options={[
                          { value: 'Male', label: 'Male' },
                          { value: 'Female', label: 'Female' },
                          { value: 'Other', label: 'Other' },
                        ]}
                        onChange={(selectedOption) => {
                          const selectedValues = selectedOption ? selectedOption.map(option => option.value) : [];
                          setFieldValue('genderYouCoach', selectedValues.join(','));
                        }}
                        isMulti
                        classNamePrefix="select"
                      />
                      <p className='text-danger'>{errors.genderYouCoach}</p>

                    </div>


                    <div className="col-xl-6 mb-3">
                      <label htmlFor="state" className="form-label">
                        State You Interested In
                      </label>

                      <Select
                        name="state"
                        options={state.map(state => ({ value: state.id, label: state.name }))}
                        className=""
                        onChange={handleStateChange}
                        isMulti
                      />



                      <p className='text-danger'>{errors.state}</p>

                    </div>
                    <div className="col-xl-6 mb-3">
                      <label htmlFor="city" className="form-label">
                        City You Coach
                      </label>

                      <Select
                        id="city"
                        name="city"
                        options={cities}

                        classNamePrefix="select"
                        isMulti
                        onChange={handleCityChange}
                      />

                      <p className='text-danger'>{errors.city}</p>

                    </div>


                    <div className="col-xl-12 mb-3">
                      <label
                        htmlFor="leagueName"
                        className="form-label"
                      >
                        League Info
                      </label>

                      <Field name="leagueName">
                        {({ field, form }) => (
                          <div>
                            <CKEditor
                              editor={ClassicEditor}
                              data={field.value}
                              onChange={(event, editor) => {
                                form.setFieldValue(field.name, editor.getData());
                              }}
                            />
                            <p className='text-danger'>{errors.leagueName}</p>
                          </div>
                        )}
                      </Field>
                    </div>

                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary me-1"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                    <Link
                      to={"#"}
                      onClick={() => setAddEmploye(false)}
                      className="btn btn-danger light ms-1"
                    >
                      Cancel
                    </Link>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default ClubOffcanvas;
