import React, { useEffect, useState } from "react";
import axios from 'axios';
import Select from 'react-select';
import NoImage from "../../../../images/no-image.jpg";
import CkEditor from "../../Forms/CkEditor/CkEditor";
import PageTitle from "../../../layouts/PageTitle";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Addannounceathlete = () => {
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [sports, setSports] = useState([]);
    const [selectedSport, setSelectedSport] = useState(null);
    const [announcementType, setAnnouncementType] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const fetchSports = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_SPORTS_API_URI}

/getallsports`, {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'multipart/form-data',
                    'authorization': `${token}`,
                }
            });

            const sportsOptions = response.data.data.map(sport => ({
                value: sport.id,
                label: sport.sportName
            }));
            // sportsOptions.unshift({ value: 'general', label: 'General' });

            setSports(sportsOptions);
        } catch (error) {
            console.error('Error fetching sports:', error);
        }
    };

    useEffect(() => {
        fetchSports();
    }, []);

    const saveAnnouncement = async () => {
        try {
            const token = localStorage.getItem('token');
            const requestData = {
                announcementTitle: title,
                announcementDescription: shortDescription,
                roleId: 4,
                sportsId: announcementType.value === 'general' ? 'general' : selectedSport.value,
                fromDate: formatDate(fromDate), // Format MM-DD-YYYY
                toDate: formatDate(toDate),     // Format MM-DD-YYYY
            };

            const response = await axios.post(`${process.env.REACT_APP_NOTIFICATION_API_URI}/addannouncement`, requestData, {
                headers: {
                    'Authorization': token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 201) {
                swal("Success", "Announcement saved successfully", "success");
                // Clear form data
                setTitle('');
                setShortDescription('');
                setSelectedSport(null);
                setAnnouncementType(null);
                setFromDate(null);
                setToDate(null);
            } else {
                swal("Error", "Failed to save announcement", "error");
            }
        } catch (error) {
            console.error(error);
            swal("Error", error.response.data.message, "error");
        }
    };

    const announcementTypeOptions = [
        { value: "general", label: 'General' },
        { value: "sport", label: 'Sport' }
    ];

    // Function to format date as MM-DD-YYYY
    const formatDate = (date) => {
        if (!date) return null;
        const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
        return formattedDate;
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="h-auto">
                            <div className="card-body p-2">
                                <div className="row">
                                    <div className="col-lg-11">
                                        <PageTitle activeMenu="Add" motherMenu="Announcement" />
                                    </div>
                                    <div className="col-lg-1">
                                        <div className="content-title border-bot">
                                            <button type="button" className="btn btn-secondary light my-2" onClick={saveAnnouncement}>
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-around">
                                    <div className="col-lg-6">
                                        <label>Type</label>
                                        <Select
                                            id="announcementTypeSelect"
                                            className=""
                                            value={announcementType}
                                            onChange={(selectedOption) => {
                                                setAnnouncementType(selectedOption);
                                                if (selectedOption.value === 'general') {
                                                    setSelectedSport({ value: 'general', label: 'General' });
                                                } else {
                                                    setSelectedSport(null);
                                                }
                                            }}
                                            options={announcementTypeOptions}
                                            placeholder="Select a type"
                                        />
                                    </div>

                                    {announcementType && announcementType.value === 'sport' && (
                                        <div className="col-lg-6">
                                            <label>Sport</label>
                                            <Select
                                                id="sportSelect"
                                                className=""
                                                value={selectedSport}
                                                onChange={setSelectedSport}
                                                options={sports}
                                                placeholder="Select a sport"
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="row mt-3">
                                    <div className="col-lg-6">
                                        <label>From Date</label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                            dateFormat="MM-dd-yyyy"
                                            className="form-control"
                                            placeholderText="Select from date"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>To Date</label>
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => setToDate(date)}
                                            dateFormat="MM-dd-yyyy"
                                            className="form-control"
                                            placeholderText="Select to date"
                                        />
                                    </div>
                                </div>

                                <CkEditor
                                    title="Title"
                                    data={title}
                                    onChange={(data) => setTitle(data)}
                                />
                                <CkEditor
                                    title="Description"
                                    data={shortDescription}
                                    onChange={(data) => setShortDescription(data)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Addannounceathlete;
