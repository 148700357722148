import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const SportOffcanvas = forwardRef((props, ref) => {
  const [AddEmploye, setAddEmploye] = useState(false);
  const [sports, setSports] = useState([]);


  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmploye(true);
    }
  }));

  useEffect(() => {
    const fetchSports = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_SPORTS_API_URI}/getallsports`, {
          headers: {
            'authorization': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        setSports(response.data);
      } catch (error) {
        console.error('Error fetching sports data:', error);
      }
    };

    fetchSports();
  }, []);
  const validationSchema = Yup.object().shape({
    specialityTitle: Yup.string()
      .required('Sport Name is required')
      .max(50, 'Sport Name must be within 50 characters'),
    sportId: Yup.string()
      .required('Sport  is required')
  });


  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const token = localStorage.getItem('token');

      const sportData = {

        specialityTitle: values.specialityTitle,
        sportId: values.sportId,
      };

      const response = await axios.post(`${process.env.REACT_APP_SPORTS_API_URI}/addspeciality`, sportData, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'authorization': `${token}`,
        }
      });

      const addedSportData = response.data.data;

      props.onSportAdded(addedSportData);

      setAddEmploye(false);
      props.onsportUpdated();
      toast.success(response?.data?.message);
    } catch (error) {
      console.error('Error adding sport:', error);
      setAddEmploye(false);
      toast.error(error?.response?.data?.message);
    }
    setSubmitting(false);
  };


  return (
    <Offcanvas show={AddEmploye} onHide={() => setAddEmploye(false)} className="offcanvas-end customeoff" placement='end'>
      <div className="offcanvas-header profile-head-h">
        <h5 className="modal-title text-white" id="#gridSystemModal">{props.Title}</h5>
        <button type="button" className="btn-close" onClick={() => setAddEmploye(false)}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <Formik
            initialValues={{ specialityTitle: '', sportId: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="row">
                  <div className="col-xl-6 mb-3">
                    <label htmlFor="exampleFormControlInput2" className="form-label">Speciality <span className="text-danger">*</span></label>
                    <Field type="text" className="form-control" id="exampleFormControlInput2" name="specialityTitle" />
                    <ErrorMessage name="specialityTitle" component="div" className="text-danger" />
                  </div>
                  <div className="col-xl-6 mb-3">
                    <label className="form-label">Sport <span className="text-danger">*</span></label>
                    <Field as="select" className="form-control" id="sportId" name="sportId">
                      <option value="">Select Sport</option>
                      {sports && Array.isArray(sports.data) && sports.data.map(sport => (
                        <option key={sport.id} value={sport.id}>{sport.sportName}</option>
                      ))}
                    </Field>
                    <ErrorMessage name="sportId" component="div" className="text-danger" />
                  </div>
                  <div>
                    <button type="submit" className="btn btn-primary me-1" disabled={isSubmitting}>Submit</button>
                    <Link to="#" onClick={() => setAddEmploye(false)} className="btn btn-danger light ms-1">Cancel</Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Offcanvas>
  );
});

export default SportOffcanvas;